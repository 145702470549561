// Require in project typefaces for speed, per
// https://spectrum.chat/gatsby-js/general/self-hosting-fonts-on-gatbsy-v2~971fa5bd-6a95-4ac6-bf92-aedc59ed1bab
// https://github.com/KyleAMathews/typefaces
require('typeface-ubuntu')

import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faBusSimple,
    faCircleCheck,
    faClock,
    faEnvelopeOpenDollar,
    faFileContract,
    faHammer,
    faHeadSideMedical,
    faHouseBuilding,
    faHouseHeart,
    faMagnifyingGlass,
    faMeterBolt,
    faOctagonExclamation,
    faPills,
    faStethoscope,
    faUtensils,
    faWalker
} from '@fortawesome/pro-regular-svg-icons'

library.add(
    faBusSimple,
    faCircleCheck,
    faClock,
    faEnvelopeOpenDollar,
    faFileContract,
    faHammer,
    faHeadSideMedical,
    faHouseBuilding,
    faHouseHeart,
    faMagnifyingGlass,
    faMeterBolt,
    faOctagonExclamation,
    faPills,
    faStethoscope,
    faUtensils,
    faWalker
)